
import {ref} from 'vue'
import {useToast} from "primevue/usetoast";
import AuthRepository from "@/services/AuthRepository";
import {useStore} from "vuex";
import router from "@/router";
import {FilterMatchMode} from "primevue/api";
import {BacSi} from "@/models/bacSi";
import DonThuocRepository from "@/services/DonThuocRepository";
import {BacSiThieu} from "@/models/bacSiThieu";

export default {
  setup() {
    const toast = useToast();
    const list = ref([] as BacSiThieu[]);
    const store = useStore();
    const loadingBar = ref(false);

    // if(!(store.state.permission == 'admin')){
    //   router.push({
    //     name: 'home'
    //   });
    // }
    const loadDs = () => {
      DonThuocRepository.danhSachBacSiThieu()
          .then((response) => {
            list.value = response.data;
          })
          .catch();
    };

    const dongBoDS = () => {
      loadingBar.value = true;
      DonThuocRepository.goiLaiDonThuoc()
          .then((response) => {
            loadDs();
            toast.add({
              severity: 'success',
              summary: 'Đồng bộ',
              detail:'Đồng bộ xong lại hệ thống',
              life: 2000
            });
            loadingBar.value = false;
          })
          .catch(err => {
            loadingBar.value = false;
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail: err.response.data,
              life: 2000
            });
          });
    }

    loadDs();

    const filters = ref({
      'global': {value: null, matchMode: FilterMatchMode.CONTAINS}
    });

    const clearFilter = () => {
      initFilters();
    };

    const initFilters = () => {
      filters.value = {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
      }
    };

    return {
      list,
      filters,
      clearFilter,
      initFilters,
      loadDs,
      dongBoDS,
      loadingBar,
    }
  }
}
