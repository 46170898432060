import Repository from './Repository'
import { AxiosResponse } from 'axios'
import { Login } from '../models/login'
import { TokenModel } from '../models/token.model'
import {Register} from "@/models/register";
import PasswordModel from "@/models/password.model";
import {UserPermission} from "@/models/permission.model";
import {BacSiCreate} from "@/models/bacSiCreate";
import {BacSi} from "@/models/bacSi";

const resource = '/donthuoc'

export default new class {
  sendDonThuoc(): Promise<AxiosResponse<any>> {
    return Repository.get<any>(`${resource}/dsDonthuoc`);
  }
  importDsBacSi(): Promise<AxiosResponse<any>> {
    return Repository.get<any>(`${resource}/taodanhsachbacsi`);
  }
  danhSachBacSi(): Promise<AxiosResponse<any>> {
    return Repository.get<any>(`${resource}/danhsachbacsi`);
  }
  dangKyBacSi(bacSiCreate: BacSiCreate): Promise<AxiosResponse<any>> {
    return Repository.post<any>(`${resource}/taobacsi`, bacSiCreate);
  }
  getBacSiById(id: number): Promise<AxiosResponse<any>> {
    return Repository.get<any>(`${resource}/bacsibyid/${id}`);
  }
  capNhatBacSi(bacSi: BacSi): Promise<AxiosResponse<any>> {
    return Repository.post<any>(`${resource}/capnhatbacsi`, bacSi);
  }
  thongTinMoiNhat(): Promise<AxiosResponse<any>> {
    return Repository.get<any>(`${resource}/thongtingannhat`);
  }
  danhSachThongTin(time1: number, time2: number): Promise<AxiosResponse<any>> {
    return Repository.get<any>(`${resource}/dsthongtin/${time1}/${time2}`);
  }
  danhSachLichSu(id: number, dt1: number, dt2: number): Promise<AxiosResponse<any>> {
    return Repository.get<any>(`${resource}/dslichsu/${id}/${dt1}/${dt2}`);
  }
  danhSachBacSiThieu(): Promise<AxiosResponse<any>> {
    return Repository.get<any>(`${resource}/danhsachbacsithieu/`);
  }
  goiLaiDonThuoc(): Promise<AxiosResponse<any>> {
    return Repository.get<any>(`${resource}/resendDt/`);
  }
  donThuocChiTiet(madon: string): Promise<AxiosResponse<any>> {
    return Repository.get<any>(`${resource}/donthuocchitiet/${madon}`);
  }
  chiTietTT(madon: string): Promise<AxiosResponse<any>> {
    return Repository.get<any>(`${resource}/chiTietTT/${madon}`);
  }
}
