
import { onMounted, ref, computed } from 'vue';
import moment from 'moment';
import { useRouter } from 'vue-router'
import {useToast} from "primevue/usetoast";
import Province from "@/models/province.models";
import District from "@/models/district.models";
import Ward from "@/models/ward.models";
import LocationRepository from "@/services/LocationRepository";
import Country from "@/models/country.models";
import {useStore} from "vuex";
import {useConfirm} from "primevue/useconfirm";
import {NguoiBenh} from "@/models/nguoiBenh";
import {ThuocKeDon} from "@/models/thuocKeDon";
import {ChanDoan} from "@/models/chanDoan";
import patientRepository from "@/services/PatientRepository";
import {NguoiBenhInfo} from "@/models/nguoiBenhInfo";
import {Thuoc} from "@/models/thuoc";
import {KhamBenh} from "@/models/khamBenh";
import {KhamBenhInfo} from "@/models/khamBenhInfo";
import {KhamBenhDetail} from "@/models/khamBenhDetail";

export default {

  setup() {
    const router = useRouter();
    const toast = useToast();
    const store = useStore();
    const nguoiBenh = ref({} as NguoiBenh);
    const thuocKeDon = ref({} as ThuocKeDon);
    const dsThuocKeDon = ref([] as ThuocKeDon[]);
    const chanDoan = ref({} as ChanDoan);
    const checkBN = ref(false);
    const keDon = ref(false);
    const lichSuKB = ref(false);
    const loadingBar = ref(false);
    const showKhamBenhDetail = ref(false);
    const date = ref();
    const listBenhNhanInfo = ref([] as NguoiBenhInfo[]);
    const idBenhNhan = ref(0);
    const dsThuoc = ref([] as Thuoc[]);
    const dsChanDoan = ref([] as ChanDoan[]);
    const khamBenh = ref({} as KhamBenh);
    const dsKhamBenhInfo = ref([] as KhamBenhInfo[]);
    const khamBenhDetail = ref({} as KhamBenhDetail);

    const dsDVT = ref([
      { label: 'gói'},
      { label: 'hộp'},
      { label: 'viên'},
      { label: 'ống'},
      { label: 'test'},
      { label: 'ml'},
      { label: 'gram'},
    ]);

    const dsCachDung = ref([
      { label: 'uống'},
      { label: 'tiêm'},
      { label: 'nhỏ'},
    ]);

    if(!(!!store.state.token)){
      router.push({
        name: 'login'
      });
    }

    const selectCalendar = () => {
      if((date.value[0] != null)&&(date.value[1] != null)) {
        patientRepository.getListKhamBenhWithTime(date.value[0]/1000, date.value[1]/1000)
            .then((response) => {
              lichSuKB.value = true;
              dsKhamBenhInfo.value = response.data;
            })
            .catch(err => {
              toast.add({
                severity: 'error',
                summary: 'Lỗi',
                detail: err.response.data,
                life: 4000
              });
            });
      }
    }

    const formatTime = (date: Date) => {
      return moment(date).format('DD/MM/YYYY HH:mm');
    };

      const clear = () => {
      }
      const confirm = useConfirm();
      const confirmSuccess = () => {
          confirm.require({
              message: 'Khai báo y tế thành công',
              header: 'Xác nhận',
              icon: 'pi pi-check-circle',
              acceptLabel: 'Khai báo mới',
              rejectLabel: 'Trang chủ',
              accept: () => {
                  clear();
              },
              reject: () => {
                  router.push({
                      name: 'home',
                  });
              }
          });
      }

    const getKhamBenhById = (id) => {
      patientRepository.getKhamBenhById(id)
          .then((response) => {
            console.log("###############$$$$$$$$$$$$%%%%%%%%% getKhamBenhById response.data: " + JSON.stringify(response.data));
            khamBenhDetail.value = response.data;
            console.log("###############$$$$$$$$$$$$%%%%%%%%% getKhamBenhById khamBenhDetail: " + JSON.stringify(khamBenhDetail.value));
            showKhamBenhDetail.value = true;
            if(khamBenhDetail.value.linkSieuAm.length > 0){
              // khamBenhDetail.value.linkSieuAm = window.location.href.split(window.location.pathname)[0] + "/" + khamBenhDetail.value.linkSieuAm;
              // khamBenhDetail.value.linkSieuAm = "https://localhost:5001/" + khamBenhDetail.value.linkSieuAm;
              khamBenhDetail.value.linkSieuAm.forEach(x => {
                x.link = window.location.href.split(window.location.pathname)[0] + "/" + x.link;
                // x.link = "https://phongkham.chuyendoisoquocgia.net" + "/" + x.link;
              })

            }
            if(khamBenhDetail.value.linkXQuang.length > 0){
              // khamBenhDetail.value.linkXQuang = window.location.href.split(window.location.pathname)[0] + "/" + khamBenhDetail.value.linkXQuang;
              // khamBenhDetail.value.linkXQuang = "https://localhost:5001/" + khamBenhDetail.value.linkXQuang;
              khamBenhDetail.value.linkXQuang.forEach(x => {
                x.link = window.location.href.split(window.location.pathname)[0] + "/" + x.link;
                // x.link = "https://phongkham.chuyendoisoquocgia.net" + "/" + x.link;
              })
            }
            console.log("###############$$$$$$$$$$$$%%%%%%%%% getKhamBenhById khamBenhDetail: " + JSON.stringify(khamBenhDetail.value));
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 4000
            });
          });
    }
    const rePrintDonThuoc = (str) => {
      const link = ref("");
      link.value = window.location.href.split(window.location.pathname)[0] + "/" + str;
      window.open(link.value, '_blank');
    }

    const responsiveOptions = ref([
      {
        breakpoint: '1300px',
        numVisible: 4
      },
      {
        breakpoint: '575px',
        numVisible: 1
      }
    ]);

    return {
      nguoiBenh,
      formatTime,
      clear,
      selectCalendar,
      dsKhamBenhInfo,
      lichSuKB,
      getKhamBenhById,
      showKhamBenhDetail,
      date,
      khamBenhDetail,
      rePrintDonThuoc,
      responsiveOptions
    }
  }
}

