

import {onMounted, ref} from 'vue'
import {useToast} from "primevue/usetoast";

import {useStore} from "vuex";
import {useRouter} from "vue-router";
import moment from "moment";
import {FilterMatchMode} from "primevue/api";
import {ThongTin} from "@/models/thongTin";
import DonThuocRepository from "@/services/DonThuocRepository";
import { BacSi } from '@/models/bacSi';
import {LichSu} from "@/models/lichSu";

export default {
  setup() {
      const store = useStore();
      const date = ref();
    const router = useRouter();
    const checkPermission = ref(false);
    const toast = useToast();
    const bacSi = ref({} as BacSi);
    const dslichSu = ref([] as LichSu[]);
    const dslichSuTemp = ref([] as LichSu[]);
    const dslichSuHinhThuc = ref([] as LichSu[]);
    const time = ref("");
    const list = ref([] as BacSi[]);
    const id = ref();
    const ma = ref("");
    const count = ref(0);
    const thongtin = ref({} as ThongTin);
    const loaiDonThuoc = ref([
      {label: 'Nội trú', value: 1},
      {label: 'Ngoại trú', value: 2},
    ]);
    const hinhThuc = ref(0);
    thongtin.value.ngoai_Tru = 0;
    thongtin.value.noi_Tru = 0;
    thongtin.value.so_Luong = 0;

    DonThuocRepository.danhSachBacSi()
        .then((response) => {
          list.value = response.data;
        })
        .catch();

    const selectBacSi = () => {
      if(id.value != null)
      {
        hinhThuc.value = 0;
        dslichSu.value = [];
        dslichSuTemp.value = [];
        count.value = dslichSu.value.length;
        if(date.value != null)
        {
          selectCalendar();
        }
        DonThuocRepository.getBacSiById(id.value)
            .then((response) => {
              bacSi.value = response.data;
            })
            .catch(err => {
              toast.add({
                severity: 'error',
                summary: 'Lỗi',
                detail: err.response.data,
                life: 2000
              });
            });
      }
    }

    const selectCalendar = () => {
      // if((date.value[0] != null)&&(date.value[1] != null)&&(id.value != null)) {
      if((date.value[0] != null)&&(date.value[1] != null)) {
        thongtin.value.ngoai_Tru = 0;
        thongtin.value.noi_Tru = 0;
        thongtin.value.so_Luong = 0;
        if(id.value == null) id.value = 0;
        DonThuocRepository.danhSachLichSu(id.value, date.value[0]/1000, date.value[1]/1000)
            .then((response) => {
              dslichSuHinhThuc.value = dslichSuTemp.value = dslichSu.value = response.data;
              dslichSu.value.forEach(x => {
              if(x.hinhthucdieutri == 2) thongtin.value.ngoai_Tru++;
              else thongtin.value.noi_Tru++;
              });
              thongtin.value.so_Luong = thongtin.value.ngoai_Tru + thongtin.value.noi_Tru;
              count.value = dslichSu.value.length;
            })
            .catch(err => {
              toast.add({
                severity: 'error',
                summary: 'Lỗi',
                detail:err.response.data,
                life: 2000
              });
            });
      }
    };
    const openLink = (id: any) => {
      let routeData = router.resolve({name: 'chitietdonthuoc', params: {id: id}});
      window.open(routeData.href, '_blank');
    };

    const searchDonThuoc = () => {
      dslichSuHinhThuc.value = dslichSu.value = dslichSuTemp.value.filter(x => x.madonthuoc.includes(ma.value));
      count.value = dslichSu.value.length;
    }

    const selectHinhThuc = () => {
      if(hinhThuc.value > 0)
      {
        dslichSu.value = dslichSuHinhThuc.value.filter(x => x.hinhthucdieutri == hinhThuc.value);
      }
      else
      {
        dslichSu.value = dslichSuHinhThuc.value;
      }
    }

    return {
      selectHinhThuc,
        bacSi,
      dslichSu,
      date,
      list,
      id,
      ma,
      count,
      thongtin,
      loaiDonThuoc,
      hinhThuc,
      selectBacSi,
      openLink,
      selectCalendar,
      searchDonThuoc,
      products: null,
      layout: 'grid',
      sortKey: null,
      sortOrder: null,
      sortField: null,
      sortOptions: [
        {label: 'Price High to Low', value: '!price'},
        {label: 'Price Low to High', value: 'price'},
      ]
    }
  }

}
