
import {computed, ref} from 'vue'
import { useStore } from 'vuex'
import { useToast } from "primevue/usetoast";
import router from "@/router";
import {CoSoKhamBenhInfo} from "@/models/coSoKhamBenhInfo";
import patientRepository from "@/services/PatientRepository";

export default {
  props: {
    isLoggedIn: {
      type: Boolean,
      required: true
    }
  },
  setup(props) {
    const store = useStore();
    const toast = useToast();
    const defaultMenu = [
    ];
    const coSoInfo = ref({} as CoSoKhamBenhInfo);

    const adminMenu = ref([
      {
        label: 'Trang chủ',
        icon: 'pi pi-fw pi-home',
        url: "/home"
      },
      {
        label: 'Phòng khám',
        icon: 'pi pi-fw pi-heart-fill',
        items: [{
          label: 'Khám bệnh',
          icon: 'pi pi-fw pi-shield',
          url: "/themKhamBenh"
        },
          {
          label: 'Đăng ký thông tin',
          icon: 'pi pi-fw pi-plus-circle',
          url: "/dangKyThongTin"
        },
          {
          label: 'Danh sách khám bệnh',
          icon: 'pi pi-fw pi-list',
          url: "/danhSachKhamBenh"
        }
        ]
      },
      // {
      //   label: 'Đồng bộ',
      //   icon: 'pi pi-fw pi-sync',
      //   items: [{
      //     label: 'Lịch sử đồng bộ',
      //     icon: 'pi pi-fw pi-history',
      //     url: "/lichsudongbo"
      //   },
      //     {
      //       label: 'Lịch sử lưu trữ',
      //       icon: 'pi pi-fw pi-database',
      //       url: "/lichsuluutru"
      //     },
      //     {
      //       label: 'Đồng bộ lại đơn thuốc',
      //       icon: 'pi pi-fw pi-replay',
      //       url: "/dongbolaidonthuoc"
      //     }
      //   ]
      // },
      // {
      //   label: 'Quản lý bác sĩ',
      //   icon: 'pi pi-fw pi-user',
      //   items: [{
      //     label: 'Thêm danh sách bác sĩ',
      //     icon: 'pi pi-fw pi-plus-circle',
      //     url: "/themdsbacsi"
      //   },
      //     {
      //       label: 'Danh sách bác sĩ',
      //       icon: 'pi pi-fw pi-users',
      //       url: "/danhsachbacsi"
      //     },
      //     {
      //       label: 'Đăng ký bác sĩ',
      //       icon: 'pi pi-fw pi-user-plus',
      //       url: "/dangkybacsi"
      //     }
      //   ]
      // },
      {
        label: 'Cấu hình',
        icon: 'pi pi-fw pi-desktop',
        items: [
        //   {
        //   label: 'Cập nhật tài khoản',
        //   icon: 'pi pi-fw pi-user-edit',
        //   url: "/capnhattaikhoan"
        // },
          {
            label: 'Thêm tài khoản',
            icon: 'pi pi-fw pi-user-plus',
            url: "/dangKyTaiKhoan"
          },
          {
            label: 'DS tài khoản',
            icon: 'pi pi-fw pi-users',
            url: "/danhSachTaiKhoan"
          }
        ]
      }
    ])
        const userMenu = ref([
      {
        label: 'Trang chủ',
        icon: 'pi pi-fw pi-home',
        url: "/home"
      },
      {
        label: 'Phòng khám',
        icon: 'pi pi-fw pi-heart-fill',
        items: [{
          label: 'Khám bệnh',
          icon: 'pi pi-fw pi-shield',
          url: "/themKhamBenh"
        },
          {
            label: 'Đăng ký thông tin',
            icon: 'pi pi-fw pi-plus-circle',
            url: "/dangKyThongTin"
          },
          {
            label: 'Danh sách khám bệnh',
            icon: 'pi pi-fw pi-list',
            url: "/danhSachKhamBenh"
          }
        ]
      }
    ])
    const appMenu = computed(() => {
      console.log("###########@@@@@@@@@@ check menu store.state.permission: " + store.state.permission);
      console.log("###########@@@@@@@@@@ check Data store: " + JSON.stringify(store.state));
      coSoInfo.value = store.state;
      if((store.state.permission == 'a0b7fcc0332dbbc0b77b05ea2b6b0ded')||(store.state.permission == 'c3284d0f94606de1fd2af172aba15bf3'))
      {
        console.log("###########@@@@@@@@@@ check menu admin: ");
        return adminMenu.value;
      }
      else if(store.state.permission == '0d8d5cd06832b29560745fe4e1b941cf')
      {
        console.log("###########@@@@@@@@@@ check menu user: ");
        return userMenu.value;
      }
      else {
        console.log("###########@@@@@@@@@@ check menu aaaaaa: ");
        return defaultMenu;
      }
    });
    const logout = () => {
      store.dispatch('clearToken');
      store.dispatch('clearPermission');
      store.dispatch('clearTenCoSo');
      store.dispatch('clearDiaChi');
      store.dispatch('clearSoDienThoai');
      store.dispatch('clearHoVaTen');
      router.push({
        name: 'login',
      });
      toast.add({
        severity:'success',
        summary: 'Đăng xuất',
        detail:'Đã xóa thông tin đăng nhập thành công',
        life: 1000
      });
    }

    return {
      appMenu,
      logout,
      coSoInfo
    }
  }
}
