
import {computed, ref} from 'vue'
import {useToast} from "primevue/usetoast";
import AuthRepository from "@/services/AuthRepository";
import {useStore} from "vuex";
import router from "@/router";
import {FilterMatchMode} from "primevue/api";
import moment from "moment";
import {useConfirm} from "primevue/useconfirm";
import {User} from "@/models/user.model";
import {UserUpdate} from "@/models/userUpdate.model";
import {UserDetail} from "@/models/userDetail.model";
import authRepository from "@/services/AuthRepository";
import {CoSoKhamBenh} from "@/models/coSoKhamBenh";
import patientRepository from "@/services/PatientRepository";

export default {
  setup() {
    const toast = useToast();
    const list = ref([] as UserDetail[]);
    const store = useStore();
    const userUpdate = ref({} as UserUpdate);
    const error = ref(null);
    userUpdate.value.password = "";
    const password = ref("");
    const checkShow = ref(false);
    const birthday = ref();
    const update = ref(false);
    const listCoSoKhamBenh = ref([] as CoSoKhamBenh[]);

    if(!(store.state.permission == 'a0b7fcc0332dbbc0b77b05ea2b6b0ded')&&!(store.state.permission == 'c3284d0f94606de1fd2af172aba15bf3')){
      router.push({
        name: 'home'
      });
    }

    const getListCoSoKB = () => {
      patientRepository.getListCoSoKhamBenh()
          .then((response) => {
            listCoSoKhamBenh.value = response.data;
          })
          .catch(err => {
          });
    }

    getListCoSoKB();
    const adminPermission = computed(() =>{
          if(store.state.permission == 'c3284d0f94606de1fd2af172aba15bf3'){
            return true;
          }else {
            return false
          }
        }
    );

    const quyenHan = ref([] as object[]);
    const quyenHanTruongPhong = ref([
      {label: "Trưởng phòng khám", value: "leader", param: 2},
      {label: "Nhân viên", value: "user", param: 3},
    ]);

    const quyenHanPhongKham = ref([
      {label: "Quản lý", value: "admin", param: 1},
      {label: "Trưởng phòng khám", value: "leader", param: 2},
      {label: "Nhân viên", value: "user", param: 3},
    ]);


    const valid = computed(()=> {
      quyenHan.value = [];
      //console.log("#############$$$$$$$$$$$$$$ valid adminPermission: " + JSON.stringify(adminPermission));
      if(adminPermission.value) {
        quyenHan.value = quyenHanPhongKham.value;
        return userUpdate.value.username && userUpdate.value.fullname && userUpdate.value.permission && userUpdate.value.idCoSo;
      }
      else {
        quyenHan.value = quyenHanTruongPhong.value;
        return userUpdate.value.username && userUpdate.value.permission && userUpdate.value.fullname;
      }
    });

    const loadData = () => {
      AuthRepository.getListUser()
          .then((response) => {
            list.value = response.data;
            list.value.forEach(x => {
              quyenHanPhongKham.value.forEach(y => {
                if (y.value == x.permission) x.permission = y.label;
              })
            })
          })
          .catch();
    }

    loadData();

    const filters = ref({
      'global': {value: null, matchMode: FilterMatchMode.CONTAINS}
    });

    const clearFilter = () => {
      initFilters();
    };

    const initFilters = () => {
      filters.value = {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
      }
    };

    const formatDateTime = (date) => {
      if(date == null) {
        return "";
      }
      else {
        return moment(String(date)).format('DD/MM/YYYY');
      }
    };

    const addHours = (time) => {
      return moment(time).format('YYYY-MM-DD hh:mm:ss');
    };

    const formatDate = (time) => {
      return moment(time).format('DD-MM-YYYY');
    };

    const confirm = useConfirm();
    // const del = (event) => {
    //   confirm.require({
    //     target: event.currentTarget,
    //     message: 'Bạn có chắc muốn xoá tài khoản này không?',
    //     icon: 'pi pi-info-circle',
    //     acceptClass: 'p-button-danger',
    //     rejectClass: 'p-button-outlined',
    //     acceptLabel: 'Đồng ý',
    //     rejectLabel: 'Huỷ',
    //     accept: () => {
    //       AuthRepository.delete(userUpdate.value.id)
    //           .then(response => {
    //             toast.add({severity:'info', summary:'Confirmed', detail:'Tài khoản đã được xoá thành công', life: 3000});
    //             // router.push({
    //             //   name: 'home',
    //             // });
    //             const temp = ref({} as UserUpdate);
    //             userUpdate.value = temp.value;
    //             checkShow.value = false;
    //             loadData();
    //           })
    //           .finally(()=>{
    //           });
    //     },
    //     reject: () => {
    //     }
    //   });
    // };

    const showUpdateInfo = (id) => {
      // update
      authRepository.getById(id)
          .then((response) => {
            userUpdate.value = response.data;
            userUpdate.value.password = '';
            update.value = true;
          })
          .catch();
    }

    const updateUser = () => {
      // update
      authRepository.userUpdate(userUpdate.value)
          .then((response) => {
            update.value = true;
            toast.add({
              severity: 'success',
              summary: 'Thành công',
              detail:'Cập nhật tài khoản thành công',
              life: 4000
            });
            loadData();
          })
          .catch();
    }

    return {
      list,
      filters,
      clearFilter,
      initFilters,
      formatDateTime,
      userUpdate,
      password,
      checkShow,
      formatDate,
      valid,
      birthday,
      update,
      showUpdateInfo,
      updateUser,
      quyenHan,
      listCoSoKhamBenh,
      adminPermission
    }
  }
}
