
import {ref} from 'vue'
import {useToast} from "primevue/usetoast";
import AuthRepository from "@/services/AuthRepository";
import {useStore} from "vuex";
import router from "@/router";
import {FilterMatchMode} from "primevue/api";
import {BacSi} from "@/models/bacSi";
import DonThuocRepository from "@/services/DonThuocRepository";

export default {
  setup() {
    const toast = useToast();
    const list = ref([] as BacSi[]);
    const store = useStore();

    // if(!(store.state.permission == 'admin')){
    //   router.push({
    //     name: 'home'
    //   });
    // }
    if(!(!!store.state.token)){
      router.push({
        name: 'login'
      });
    }

    DonThuocRepository.danhSachBacSi()
        .then((response) => {
          list.value = response.data;
        })
        .catch();



    const filters = ref({
      'global': {value: null, matchMode: FilterMatchMode.CONTAINS}
    });

    const clearFilter = () => {
      initFilters();
    };

    const initFilters = () => {
      filters.value = {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
      }
    };

    return {
      list,
      filters,
      clearFilter,
      initFilters,
    }
  }
}
