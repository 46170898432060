import Repository from './Repository';
import Country from "../models/country.models";
import Province from "../models/province.models";
import District from "../models/district.models";
import {AxiosResponse} from "axios";
import Ward from "@/models/ward.models";
const resource = '/location';

export default new class{
    getCountry(): Promise<AxiosResponse<Country[]>>{
        return Repository.get<Country[]>(`${resource}/country`);
    }

    getProvince(): Promise<AxiosResponse<Province[]>>{
        return Repository.get<Province[]>(`${resource}/province`);
    }

    getDistrict(id: number): Promise<AxiosResponse<District[]>>{
        return Repository.get<District[]>(`${resource}/district/${id}`);
    }

    getWard(id: number): Promise<AxiosResponse<Ward[]>>{
        return Repository.get<Ward[]>(`${resource}/ward/${id}`);
    }
}
