
import LoginPage from "@/pages/LoginPage.vue";
import thongKePage from "@/pages/ThongKePage.vue";
// import SendMessagePage from "@/pages/SendMessagePage.vue";

import { useStore } from 'vuex'
import { computed } from 'vue'
import {useRouter} from "vue-router";

export default {
  components: {
    LoginPage,
    // SendMessagePage
    thongKePage
  },
  setup(){
    const store = useStore();
    const isLoggedIn = store.state.token;
    const router = useRouter();

    console.log("isLoggedIn: " + isLoggedIn);
    console.log("isLoggedIn: " + JSON.stringify(isLoggedIn));
    console.log("isLoggedIn store.state.token: " + store.state.token);
   if(store.state.token.length < 1)
   {
     router.push({
       name: 'login'
     });
   }
    return {
      isLoggedIn,
    }
  }
}
