
import { onMounted, ref, computed } from 'vue';
import moment from 'moment';
import { useRouter } from 'vue-router'
import {useToast} from "primevue/usetoast";
import Province from "@/models/province.models";
import District from "@/models/district.models";
import Ward from "@/models/ward.models";
import LocationRepository from "@/services/LocationRepository";
import Country from "@/models/country.models";
import {useStore} from "vuex";
import {useConfirm} from "primevue/useconfirm";
import {NguoiBenh} from "@/models/nguoiBenh";
import {ThuocKeDon} from "@/models/thuocKeDon";
import {ChanDoan} from "@/models/chanDoan";
import patientRepository from "@/services/PatientRepository";
import {NguoiBenhInfo} from "@/models/nguoiBenhInfo";
import {Thuoc} from "@/models/thuoc";
import {KhamBenh} from "@/models/khamBenh";
import {KhamBenhInfo} from "@/models/khamBenhInfo";
import {KhamBenhDetail} from "@/models/khamBenhDetail";
import {FileDetail} from "@/models/fileDetail.models";
import axios from "axios";
import {ImageFile} from "@/models/imageFile.models";

export default {

  setup() {
    const router = useRouter();
    const toast = useToast();
    const isSubmitting = ref(false);
    const countries = ref([] as Country[]);
    const provinces = ref([] as Province[]);
    const districts = ref([] as District[]);
    const wards = ref([] as Ward[]);
    const province = ref(48);
    const district = ref(0);
    const ward = ref(0);
    const country = ref("VN");
    const qrcodesearch = ref(false);
    const tokenAccess = ref(false);
    const loadingGetResponse = ref(false);
    const store = useStore();
    const addressstring = ref([] as string[]);
    const nguoiBenh = ref({} as NguoiBenh);
    const thuocKeDon = ref({} as ThuocKeDon);
    const dsThuocKeDon = ref([] as ThuocKeDon[]);
    const qrcodesearchinput = ref(false);
    const index = ref(0);
    const chanDoan = ref({} as ChanDoan);
    const checkBN = ref(false);
    const keDon = ref(false);
    const lichSuKB = ref(false);
    const loadingBar = ref(false);
    const showKhamBenhDetail = ref(false);
    const date = ref();
    const listBenhNhanInfo = ref([] as NguoiBenhInfo[]);
    const idBenhNhan = ref(0);
    const dsThuoc = ref([] as Thuoc[]);
    const dsChanDoan = ref([] as ChanDoan[]);
    const khamBenh = ref({} as KhamBenh);
    const dsKhamBenh = ref([] as KhamBenh[]);
    const dsKhamBenhInfo = ref([] as KhamBenhInfo[]);
    const khamBenhDetail = ref({} as KhamBenhDetail);
   // const checkUpdate = ref(false);
    //const checkInfo = ref(true);

    const dsDVT = ref([
      { label: 'gói'},
      { label: 'hộp'},
      { label: 'viên'},
      { label: 'ống'},
      { label: 'test'},
      { label: 'ml'},
      { label: 'gram'},
    ]);

    const dsCachDung = ref([
      { label: 'uống'},
      { label: 'tiêm'},
      { label: 'nhỏ'},
    ]);
    const dsLoiDan= ref([
      { label: 'Ngày 2 lần , Sáng 2 viên, Chiều 2 viên'},
      { label: 'Ngày 3 lần, Sáng 1 viên, Trưa 1 viên, Chiều 1 viên'},
      { label: 'Ngày 1 lần, Sáng 1 viên'},
    ]);
    if(!(!!store.state.token)){
      router.push({
        name: 'login'
      });
    }

    const valid = computed(()=> nguoiBenh.value.hoVaTen && nguoiBenh.value.gioiTinh && date.value && nguoiBenh.value.cccd && nguoiBenh.value.diaChi && province.value && district.value && ward.value);
    const validThuoc = computed(()=> thuocKeDon.value.tenThuoc && thuocKeDon.value.donViTinh && thuocKeDon.value.soLuong);
    const validKhamBenh = computed(()=> chanDoan.value.maChanDoan && chanDoan.value.tenChanDoan && chanDoan.value.ketLuan);
    const checkUpdate = computed(() => {
      if(nguoiBenh.value.id > 0) return true;
      else return false;
    });

    if(store.state.token.length > 0)
      {
          tokenAccess.value = true;
      }
      else {
          tokenAccess.value = false;
      }

    patientRepository.getListThuoc()
        .then((response) => {
          dsThuoc.value = response.data;
        })
        .catch();
      patientRepository.getListChanDoan()
        .then((response) => {
          dsChanDoan.value = response.data;
        })
        .catch();

      const slReason = ref(1);
      const selectReason = (id: number) => {
          slReason.value = id;
      }

      const createKeDon = () => {
        keDon.value = true;
        const tempKb = ref({} as KhamBenh);
        const tempTKD = ref({} as ThuocKeDon);
        const tempCD = ref({} as ChanDoan);
        khamBenh.value = tempKb.value;
        thuocKeDon.value = tempTKD.value;
        chanDoan.value = tempCD.value;
        listFileName.value = [];
        listFileNameFim.value = [];
        dsThuocKeDon.value = [];
      }

      const createNguoiBenh = () => {
        provinces.value.forEach(x => {
          if(x.provinceCode == province.value)
          {
            nguoiBenh.value.thanhPho = x.provinceName;
            nguoiBenh.value.maThanhPho = x.provinceCode;
          }
        });
        districts.value.forEach(x => {
          if(x.districtCode == district.value)
          {
            nguoiBenh.value.quan = x.districtName;
            nguoiBenh.value.maQuan = x.districtCode;
          }
        });
        wards.value.forEach(x => {
          if(x.wardCode == ward.value)
          {
            nguoiBenh.value.phuong = x.wardName;
            nguoiBenh.value.maPhuong = x.wardCode;
          }
        });

        countries.value.forEach(x => {
         if(x.countryCodeName == nguoiBenh.value.maQuocGia)
         {
           nguoiBenh.value.quocGia = x.countryName;
         }
        });
        nguoiBenh.value.bhyt = "";
        nguoiBenh.value.idCoSo = 0;
        nguoiBenh.value.maNguoiBenh = "";

        // console.log("@@@@@###############$$$$$$$$$$$$$$$$$ createNguoiBenh nguoiBenh: " + JSON.stringify(nguoiBenh.value));

        nguoiBenh.value.ngayThangNamSinhChuoi = moment(date.value).format('DD/MM/YYYY');
        patientRepository.createBenhNhan(nguoiBenh.value)
            .then((response) => {
              nguoiBenh.value = response.data;
              province.value = nguoiBenh.value.maThanhPho;
              district.value = nguoiBenh.value.maQuan;
              ward.value = nguoiBenh.value.maPhuong;
              date.value = nguoiBenh.value.ngayThangNamSinhChuoi;
              getListBNInfo();
              idBenhNhan.value = nguoiBenh.value.id;

              selectProvince();
              selectDistrict();
              checkBN.value = true;
              // toast.add({
              //   severity: 'success',
              //   summary: 'Đăng ký',
              //   detail: 'Đăng ký bác sĩ thành công vào hệ thống',
              //   life: 2000
              // });
            })
            .catch(err => {
              toast.add({
                severity: 'error',
                summary: 'Lỗi',
                detail:err.response.data,
                life: 4000
              });
            });
      }

      const getListBNInfo = () => {
        patientRepository.getBenhNhanInfo()
          .then((response) => {
            listBenhNhanInfo.value = response.data;
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 4000
            });
          });
      };

    const getListKhamBenh = () => {
      patientRepository.getListKhamBenh(idBenhNhan.value)
          .then((response) => {
            lichSuKB.value = true;
            dsKhamBenhInfo.value = response.data;
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 4000
            });
          });
    }

    const createKhamBenh = () => {
      khamBenh.value.linkDonThuoc = "";
        khamBenh.value.idNguoiBenh = idBenhNhan.value;
        khamBenh.value.chanDoan = JSON.stringify(chanDoan.value);
        khamBenh.value.thongTinThuoc = JSON.stringify(dsThuocKeDon.value);
      loadingBar.value = true;
      patientRepository.createKhamBenh(khamBenh.value)
          .then((response) => {
            loadingBar.value = false;
            khamBenh.value = response.data;
            if(listFileName.value.length > 0 || listFileNameFim.value.length > 0)
            {
              upload(khamBenh.value.id);
            }
            toast.add({
              severity: 'success',
              summary: 'Thành công',
              detail:'Thêm khám bệnh thành công',
              life: 4000
            });
          })
          .catch(err => {
            loadingBar.value = false;
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 4000
            });
          });
    }

    const updateBenhNhan = () => {
      provinces.value.forEach(x => {
        if(x.provinceCode == province.value)
        {
          nguoiBenh.value.thanhPho = x.provinceName;
          nguoiBenh.value.maThanhPho = x.provinceCode;
        }
      });
      districts.value.forEach(x => {
        if(x.districtCode == district.value)
        {
          nguoiBenh.value.quan = x.districtName;
          nguoiBenh.value.maQuan = x.districtCode;
        }
      });
      wards.value.forEach(x => {
        if(x.wardCode == ward.value)
        {
          nguoiBenh.value.phuong = x.wardName;
          nguoiBenh.value.maPhuong = x.wardCode;
        }
      });

      countries.value.forEach(x => {
        if(x.countryCodeName == nguoiBenh.value.maQuocGia)
        {
          nguoiBenh.value.quocGia = x.countryName;
        }
      });
      patientRepository.updateBenhNhan(nguoiBenh.value)
          .then((response) => {
            toast.add({
              severity: 'success',
              summary: 'Thành công',
              detail:'Thêm khám bệnh thành công',
              life: 4000
            });
          })
          .catch(err => {
            loadingBar.value = false;
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 4000
            });
          });
    }

    getListBNInfo();

    const SearchBenhNhanByCccd = () => {
      patientRepository.getBenhNhanByCccd(nguoiBenh.value.cccd)
          .then((response) => {
            nguoiBenh.value = response.data;
            province.value = nguoiBenh.value.maThanhPho;
            district.value = nguoiBenh.value.maQuan;
            ward.value = nguoiBenh.value.maPhuong;
            date.value = nguoiBenh.value.ngayThangNamSinhChuoi;
            idBenhNhan.value = nguoiBenh.value.id;

            selectProvince();
            selectDistrict();
            checkBN.value = true;
          })
          .catch(err => {
            // toast.add({
            //   severity: 'error',
            //   summary: 'Lỗi',
            //   detail:err.response.data,
            //   life: 4000
            // });
          });
    }

      LocationRepository.getCountry()
            .then((response) => {
              countries.value = response.data;
                nguoiBenh.value.quocGia = "Việt Nam";
            })
            .catch();
    LocationRepository.getProvince()
            .then((response) => {
              provinces.value = response.data;
            })
            .catch();

    const selectProvince = () => {
      LocationRepository.getDistrict(province.value)
        .then((response) => {
            districts.value = response.data;
              if(addressstring.value[2] != null)
              {
                  districts.value.forEach(x => {if(x.districtName.includes(addressstring.value[2]))
                  {
                      district.value = x.districtCode;
                  }
                  });
                  selectDistrict();
              }
        })
        .catch();
    }

    const selectDistrict = () => {
      LocationRepository.getWard(district.value)
        .then((response) => {
            wards.value = response.data;
              if(addressstring.value[1] != null)
              {
                  wards.value.forEach(x => {if(x.wardName.includes(addressstring.value[1]))
                  {
                      ward.value = x.wardCode;
                  }});
              }
        })
        .catch();
    }

   selectProvince();
    const formatTime = (date: Date) => {
      return moment(date).format('DD/MM/YYYY HH:mm');
    };

      const clear = () => {
      }
      const confirm = useConfirm();
      const confirmSuccess = () => {
          confirm.require({
              message: 'Khai báo y tế thành công',
              header: 'Xác nhận',
              icon: 'pi pi-check-circle',
              acceptLabel: 'Khai báo mới',
              rejectLabel: 'Trang chủ',
              accept: () => {
                  clear();
              },
              reject: () => {
                  router.push({
                      name: 'home',
                  });
              }
          });
      }


    const onDecode = (result: any) =>
    {
      if(result.toString().length > 0)
        splitString(result);
    };
    const splitString = (value: any) => {
   if(value != null)
        qrcodesearch.value = false;
      const cccdstring = ref([] as string[]);
      cccdstring.value = value.split("|");
      checkBN.value = false;
      const cccd = ref("");
      cccd.value = nguoiBenh.value.cccd;
      const nguoiBenhTemp = ref({} as NguoiBenh);
     nguoiBenh.value = nguoiBenhTemp.value;
      date.value ="";
      idBenhNhan.value = 0;

      // console.log("#####################@@@@@@@@@@@@@ splitString");
      if((cccdstring.value.length != 7)||(!checkQrcode(cccdstring).value))
      {
        nguoiBenh.value.cccd = cccd.value;
        // console.log("#####################@@@@@@@@@@@@@ splitString 11111111");
        SearchBenhNhanByCccd();
      }
      else
      {
        // console.log("#####################@@@@@@@@@@@@@ splitString 22222");
        nguoiBenh.value.cccd = cccdstring.value[0];
        nguoiBenh.value.cmnd = cccdstring.value[1];
        nguoiBenh.value.hoVaTen = cccdstring.value[2];
        // nguoiBenh.value.ngayThangNamSinhChuoi = cccdstring.value[3].substring(4,8);
        nguoiBenh.value.gioiTinh = cccdstring.value[4].toLocaleLowerCase();
        date.value = new Date(parseInt(cccdstring.value[3].substring(4,8)), parseInt(cccdstring.value[3].substring(2,4))-1, parseInt(cccdstring.value[3].substring(0,2)));
        nguoiBenh.value.ngayThangNamSinhChuoi = moment(date.value).format('DD/MM/YYYY');
        // console.log("#####################@@@@@@@@@@@@@ splitString 22222 Datetime: " + new Date(parseInt(cccdstring.value[3].substring(4,8)), parseInt(cccdstring.value[3].substring(2,4))-1, parseInt(cccdstring.value[3].substring(0,2))));
        // console.log("#####################@@@@@@@@@@@@@ splitString 22222 checkBN: " + checkBN.value);
        // console.log("#####################@@@@@@@@@@@@@ splitString 22222 nguoiBenh: " + JSON.stringify(nguoiBenh.value));
        getLocation(cccdstring.value[5]);
        SearchBenhNhanByCccd();
        // console.log("#####################@@@@@@@@@@@@@ splitString 444444444 checkBN: " + checkBN.value);
        // console.log("#####################@@@@@@@@@@@@@ splitString 333333 nguoiBenh: " + JSON.stringify(nguoiBenh.value));
      }
    };

    const checkQrcode = (qrcodeString: any) => {
      const checkValue = ref(true);
      qrcodeString.value.forEach(x => {
        if(x.length == 0) checkValue.value = false;
      });

      return checkValue;
    };

    const getLocation = (address: any) =>{
      addressstring.value = address.split(", ");
      nguoiBenh.value.diaChi = addressstring.value[0];
      provinces.value.forEach(x => {if(x.provinceName.includes(addressstring.value[3])) province.value = x.provinceCode;});
      selectProvince();
    };

    const searchByQrcode = () => {
      clear();
      qrcodesearchinput.value = !qrcodesearchinput.value;
      qrcodesearch.value = false;
      nguoiBenh.value.hoVaTen = "";
      nguoiBenh.value.ngayThangNamSinhChuoi = "";
      nguoiBenh.value.gioiTinh = "";
      nguoiBenh.value.cmnd = "";
      nguoiBenh.value.diaChi = "";
    };

    const pressEnterKey = () => {
      // if (event.keyCode === 13) {
      //   console.log("################################ pressEnterKey");
      splitString(nguoiBenh.value.cccd);
      // }
    }

    const addThuoc = () => {
      thuocKeDon.value.id = index.value + 1;
      index.value++;

      dsThuocKeDon.value.push(thuocKeDon.value);
      const temp = ref({} as ThuocKeDon);
      thuocKeDon.value = temp.value;
    }

    const deleteThuoc = (id: number) => {
      // console.log("################################ deleteThuoc id: " + id);
      dsThuocKeDon.value.splice(dsThuocKeDon.value.findIndex(x => x.id == id), 1);
      dsThuocKeDon.value.forEach(x => {
        if(x.id > id)
        {
          x.id = x.id - 1;
        }
      });
      index.value--;
      //console.log("################################ deleteThuoc id: " + id);
      //console.log("################################ deleteThuoc dsThuocKeDon: " + JSON.stringify(dsThuocKeDon.value));
    }

    const calendarSelect = () => {
      nguoiBenh.value.ngayThangNamSinhChuoi = moment(date.value).format('DD/MM/YYYY');
    }

    const SearchBenhNhanById = () => {
      if(idBenhNhan.value == null || idBenhNhan.value < 1)
      {
        const temp = ref({} as NguoiBenh);
        nguoiBenh.value = temp.value;
        province.value = 48;
        district.value = 0;
        ward.value = 0;
        date.value="";
        checkBN.value = false;
      }
      else {
        patientRepository.getBenhNhanById(idBenhNhan.value)
            .then((response) => {
              nguoiBenh.value = response.data;
              province.value = nguoiBenh.value.maThanhPho;
              district.value = nguoiBenh.value.maQuan;
              ward.value = nguoiBenh.value.maPhuong;
              date.value = nguoiBenh.value.ngayThangNamSinhChuoi;

              selectProvince();
              selectDistrict();
              checkBN.value = true;
            })
            .catch(err => {
              toast.add({
                severity: 'error',
                summary: 'Lỗi',
                detail: err.response.data,
                life: 4000
              });
            });
      }
    }

    const selectChanDoan = () => {
      dsChanDoan.value.forEach(x => {
        if(x.maChanDoan == chanDoan.value.maChanDoan)
        {
          chanDoan.value.tenChanDoan = x.tenChanDoan;
          chanDoan.value.ketLuan = x.ketLuan;
        }
      })
    }

    const selectThuoc = () => {
      dsThuoc.value.forEach(x => {
        if(x.tenThuoc == thuocKeDon.value.tenThuoc)
        {
          if(x.hoatChat.length > 0) {
            thuocKeDon.value.hoatChat = x.hoatChat;
          }
        }
      })
    }

    const showDT = ref(false);


    const showDonThuoc = () => {
      // showDT.value = true;
      const link = ref("");
      link.value = window.location.href.split(window.location.pathname)[0] + "/" + khamBenh.value.linkDonThuoc;
      // link.value = window.location.href.split(window.location.pathname)[0] + "/" + khamBenh.value.linkDonThuoc;
      window.open(link.value, '_blank');
    }

    const rePrintDonThuoc = (str) => {
      const link = ref("");
      link.value = window.location.href.split(window.location.pathname)[0] + "/" + str;
      window.open(link.value, '_blank');
    }

    const tiepNhanMoi = () => {
      const temp = ref({} as NguoiBenh);
      nguoiBenh.value = temp.value;
      district.value = 0;
      ward.value = 0;
      checkBN.value  = false;
      date.value ="";
      idBenhNhan.value = 0;
      lichSuKB.value = false;
    }

    const getKhamBenhById = (id) => {
      listFileName.value = [];
      listFileNameFim.value = [];
      patientRepository.getKhamBenhById(id)
          .then((response) => {
            khamBenhDetail.value = response.data;
            showKhamBenhDetail.value = true;
            if(khamBenhDetail.value.linkSieuAm.length > 0){
              // khamBenhDetail.value.linkSieuAm = window.location.href.split(window.location.pathname)[0] + "/" + khamBenhDetail.value.linkSieuAm;
              // khamBenhDetail.value.linkSieuAm = "https://localhost:5001/" + khamBenhDetail.value.linkSieuAm;
              khamBenhDetail.value.linkSieuAm.forEach(x => {
                x.link = window.location.href.split(window.location.pathname)[0] + "/" + x.link;
                // x.link = "https://phongkham.chuyendoisoquocgia.net" + "/" + x.link;
              })

            }
            if(khamBenhDetail.value.linkXQuang.length > 0){
              // khamBenhDetail.value.linkXQuang = window.location.href.split(window.location.pathname)[0] + "/" + khamBenhDetail.value.linkXQuang;
              // khamBenhDetail.value.linkXQuang = "https://localhost:5001/" + khamBenhDetail.value.linkXQuang;
              khamBenhDetail.value.linkXQuang.forEach(x => {
                x.link = window.location.href.split(window.location.pathname)[0] + "/" + x.link;
                // x.link = "https://phongkham.chuyendoisoquocgia.net" + "/" + x.link;
              })
            }
            console.log("###############$$$$$$$$$$$$%%%%%%%%% getKhamBenhById khamBenhDetail: " + JSON.stringify(khamBenhDetail.value));
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 4000
            });
          });
    }

    let listFileName = ref([] as FileDetail[]);
    let listFileNameFim = ref([] as FileDetail[]);
    const fileName = ref("");
    const fileNameFim = ref("");
    const loadingBarProcess = ref(false);
    const srcImage = ref();
    const imageXQuang = ref([] as ImageFile[]);
    const imageSieuAm = ref([] as ImageFile[]);
    const fileChange = (fileList) => {
      fileName.value = fileList[0].name;
      const file = fileList[0];
      srcImage.value = URL.createObjectURL(
          file,
      );
      listFileName.value = [];
      var leng = fileList.length;
      for (var i = 0; i < leng; i++)
      {
        var filetemp = ref({} as FileDetail);
        filetemp.value.nameFile = fileList[i].name;
        filetemp.value.value = fileList[i];
        filetemp.value.link = URL.createObjectURL(
            fileList[i],
        );
        listFileName.value.push(filetemp.value);
      }
    };

    const srcImageFim = ref();
    const fileChangeFim = (fileList) => {
      fileNameFim.value = fileList[0].name;
      const file = fileList[0];
      srcImageFim.value = URL.createObjectURL(
          file,
      );
      listFileNameFim.value = [];
      var leng = fileList.length;
      for (var i = 0; i < leng; i++)
      {
        var filetemp = ref({} as FileDetail);
        filetemp.value.nameFile = fileList[i].name;
        filetemp.value.value = fileList[i];
        filetemp.value.link = URL.createObjectURL(
            fileList[i],
        );
        listFileNameFim.value.push(filetemp.value);
      }
    };

    const upload = (id: number) => {
      {
        var files = new FormData();
          //siêu âm == 1
          listFileNameFim.value.forEach(x =>
          {
            files.append("formFiles", x.value, x.nameFile + ">>>1");
          });

          // xquang == 2
          listFileName.value.forEach(x =>
          {
            files.append("formFiles", x.value, x.nameFile + ">>>2");
          });

        loadingBar.value = true;
        axios.post(`/api/file/UploadCDHA/${id}`, files,
            {
              headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data'
              }
            }).then(response => {
          loadingBar.value = false;
          // listFileName.value = [];
          // show.value = true;
          // toast.add({
          //   severity: 'success',
          //   summary: 'Thành công',
          //   detail:'Tải danh sách lên thành công',
          //   life: 2000
          // });
        })
            .catch(err => {
              toast.add({
                severity: 'error',
                summary: 'Lỗi',
                detail:err.response.data,
                life: 2500
              })});
      }
    };

    const uploadMore = () => {
      {
        var files = new FormData();
        //siêu âm == 1
        listFileNameFim.value.forEach(x =>
        {
          files.append("formFiles", x.value, x.nameFile + ">>>1");
        });

        // xquang == 2
        listFileName.value.forEach(x =>
        {
          files.append("formFiles", x.value, x.nameFile + ">>>2");
        });

        loadingBar.value = true;
        axios.post(`/api/file/UploadCDHAMore/${khamBenhDetail.value.id}`, files,
            {
              headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data'
              }
            }).then(response => {
          loadingBar.value = false;
          getKhamBenhById(khamBenhDetail.value.id);

        })
            .catch(err => {
              toast.add({
                severity: 'error',
                summary: 'Lỗi',
                detail:err.response.data,
                life: 2500
              })});
      }
    };

    const deleteFile = (name: string) =>
    {
      listFileName.value.findIndex(x => x.nameFile == name);
      listFileName.value.splice(listFileName.value.findIndex(x => x.nameFile == name), 1);
    };

    const deleteFileFim = (name: string) =>
    {
      listFileNameFim.value.findIndex(x => x.nameFile == name);
      listFileNameFim.value.splice(listFileNameFim.value.findIndex(x => x.nameFile == name), 1);
    };

    const responsiveOptions = ref([
      {
        breakpoint: '1300px',
        numVisible: 4
      },
      {
        breakpoint: '575px',
        numVisible: 1
      }
    ]);

    return {
      nguoiBenh,
      formatTime,
      isSubmitting,
      province,
      provinces,
      district,
      districts,
      countries,
      ward,
      wards,
      selectProvince,
      selectDistrict,
      country,
      clear,
      qrcodesearch,
      onDecode,
      slReason,
      selectReason,
      tokenAccess,
      loadingGetResponse,
      searchByQrcode,
      qrcodesearchinput,
      pressEnterKey,
      thuocKeDon,
      dsThuocKeDon,
      addThuoc,
      deleteThuoc,
      chanDoan,
      createNguoiBenh,
      valid,
      checkBN,
      createKeDon,
      keDon,
      date,
      calendarSelect,
      listBenhNhanInfo,
      idBenhNhan,
      SearchBenhNhanById,
      dsThuoc,
      dsChanDoan,
      selectChanDoan,
      showDonThuoc,
      createKhamBenh,
      dsDVT,
      khamBenh,
      showDT,
      tiepNhanMoi,
      dsCachDung,
      getListKhamBenh,
      dsKhamBenhInfo,
      lichSuKB,
      getKhamBenhById,
      showKhamBenhDetail,
      khamBenhDetail,
      loadingBar,
      validThuoc,
      selectThuoc,
      validKhamBenh,
      rePrintDonThuoc,
      fileChange,
      upload,
      fileName,
      srcImage,
      listFileNameFim,
      fileNameFim,
      srcImageFim,
      fileChangeFim,
      listFileName,
      checkUpdate,
      updateBenhNhan,
      deleteFile,
      deleteFileFim,
      responsiveOptions,
      uploadMore,
      dsLoiDan
    }
  }
}

