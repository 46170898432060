

import { computed, ref } from 'vue'
import AuthRepository from '@/services/AuthRepository'
import { useToast } from "primevue/usetoast";
import { useRouter } from 'vue-router';
import {useStore} from "vuex";
import {useConfirm} from "primevue/useconfirm";
import donThuocRepository from "@/services/DonThuocRepository";
import {BacSi} from "@/models/bacSi";
import DonThuocRepository from "@/services/DonThuocRepository";

export default {
  props: {
    id: Number,
  },

  setup(props) {
    const router = useRouter();
    const bacSi = ref({} as BacSi);
    const toast = useToast();
    const store = useStore();
    const error = ref(null);

    if(!(!!store.state.token)){
      router.push({
        name: 'login'
      });
    }

    donThuocRepository.getBacSiById(props.id)
    .then((response) => {
      bacSi.value = response.data;
    })
    .catch(err => {
      toast.add({
        severity: 'error',
        summary: 'Lỗi',
        detail:err.response.data,
        life: 2000
      });
    });

    const valid = computed(()=> bacSi.value.hovaten && bacSi.value.cchn && bacSi.value.password);

    // if(!(store.state.permission == 'admin')){
    //   router.push({
    //     name: 'home'
    //   });
    // }
    const doUpdate = () => {
        DonThuocRepository.capNhatBacSi(bacSi.value)
                .then((response) => {
                  toast.add({
                    severity: 'success',
                    summary: 'Cập nhật',
                    detail: 'Cập nhật thông tin tài khoản thành công',
                    life: 2000
                  });
                  // router.push({
                  //   name: 'home'
                  // });
                })
                .catch(err => {
                  toast.add({
                    severity: 'error',
                    summary: 'Lỗi',
                    detail:err.response.data,
                    life: 2000
                  });
                });
      };

    return {
      doUpdate,
      valid,
      bacSi
    }
  }
}

