

import { computed, ref } from 'vue'
import AuthRepository from '@/services/AuthRepository'
import { useToast } from "primevue/usetoast";
import { useRouter } from 'vue-router'
import {Register} from "../models/register";
import {useStore} from "vuex";
import moment from "moment";
import {UserCreate} from "@/models/userCreate.model";
import {CoSoKhamBenhCreate} from "@/models/coSoKhamBenhCreate";
import patientRepository from "@/services/PatientRepository";
import {CoSoKhamBenh} from "@/models/coSoKhamBenh";
import router from "@/router";

export default {

  setup(){
    const router = useRouter();
    const user = ref({} as UserCreate);
    const toast = useToast();
    const store = useStore();
    const error = ref(null);
    const date = ref();
    const coSoKhamBenhCreate = ref({} as CoSoKhamBenhCreate);
    const listCoSoKhamBenh = ref([] as CoSoKhamBenh[]);

    const validCoSo = computed(()=> coSoKhamBenhCreate.value.tenCoSo && coSoKhamBenhCreate.value.diaChi && coSoKhamBenhCreate.value.soDienThoai);
    // if(!(store.state.permission == 'admin')){
    //   router.push({
    //     name: 'home'
    //   });
    // }
    // c3284d0f94606de1fd2af172aba15bf3
    if(!(store.state.permission == 'a0b7fcc0332dbbc0b77b05ea2b6b0ded')&&!(store.state.permission == 'c3284d0f94606de1fd2af172aba15bf3')){
      router.push({
        name: 'home'
      });
    }
    const adminPermission = computed(() =>{
          if(store.state.permission == 'c3284d0f94606de1fd2af172aba15bf3'){
            return true;
          }else {
            return false
          }
      }
    );
    const quyenHan = ref([] as object[]);
    const quyenHanTruongPhong = ref([
      {label: "Trưởng phòng khám", value: "leader", param: 2},
      {label: "Nhân viên", value: "user", param: 3},
    ]);

    const quyenHanPhongKham = ref([
      {label: "Quản lý", value: "admin", param: 1},
      {label: "Trưởng phòng khám", value: "leader", param: 2},
      {label: "Nhân viên", value: "user", param: 3},
    ]);


    const valid = computed(()=> {
      quyenHan.value = [];
      console.log("#############$$$$$$$$$$$$$$ valid adminPermission: " + JSON.stringify(adminPermission));
      if(adminPermission.value) {
        quyenHan.value = quyenHanPhongKham.value;
        return user.value.password && user.value.username && user.value.fullname && user.value.permission && user.value.idCoSo;
      }
      else {
        quyenHan.value = quyenHanTruongPhong.value;
        return user.value.password && user.value.username && user.value.permission && user.value.fullname;
      }
    });

    const getListCoSoKB = () => {
      patientRepository.getListCoSoKhamBenh()
          .then((response) => {
            listCoSoKhamBenh.value = response.data;
          })
          .catch(err => {
          });
    }

    getListCoSoKB();

    const doRegister = () => {
      if(user.value.password.length < 6)
      {
        toast.add({
          severity: 'error',
          summary: 'Thất bại',
          detail: 'Mật khẩu không hợp lệ, vui lòng nhập lại mật khẩu',
          life: 2000
        });
      }
      else {
       // register.value.birthd
        AuthRepository.registerUser(user.value)
                .then((response) => {
                  toast.add({
                    severity: 'success',
                    summary: 'Đăng ký',
                    detail: 'Đăng ký thành viên thành công vào hệ thống',
                    life: 2000
                  });
                  // router.push({
                  //   name: 'home'
                  // });
                })
                .catch(err => {
                  toast.add({
                    severity: 'error',
                    summary: 'Lỗi',
                    detail:err.response.data,
                    life: 2000
                  });
                });
      };
    }

    const createCoSoKB = () => {
        patientRepository.createCoSoKhamBenh(coSoKhamBenhCreate.value)
            .then((response) => {
              toast.add({
                severity: 'success',
                summary: 'Thành công',
                detail: 'Thêm cơ sở khám chữa bệnh thành công',
                life: 2000
              });
              getListCoSoKB();
            })
            .catch(err => {
              toast.add({
                severity: 'error',
                summary: 'Lỗi',
                detail:err.response.data,
                life: 2000
              });
            });
    }

    return {
      user,
      doRegister,
      valid,
      quyenHan,
      quyenHanPhongKham,
      error,
      date,
      coSoKhamBenhCreate,
      validCoSo,
      createCoSoKB,
      adminPermission,
      listCoSoKhamBenh
    }
  }
}

