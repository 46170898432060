

import { computed, ref } from 'vue'
import { useToast } from "primevue/usetoast";
import { useRouter } from 'vue-router'
import {useStore} from "vuex";
import {BacSiCreate} from "@/models/bacSiCreate";
import {ChanDoan} from "@/models/chanDoan";
import patientRepository from "@/services/PatientRepository";
import {Thuoc} from "@/models/thuoc";

export default {

  setup(){
    const router = useRouter();
    const register = ref({} as BacSiCreate);
    const toast = useToast();
    const store = useStore();
    const error = ref(null);
    const chanDoan = ref({} as ChanDoan);
    const listChanDoan = ref([] as ChanDoan[]);
    const thuoc = ref({} as Thuoc);
    const listThuoc = ref([] as Thuoc[]);

    const valid = computed(()=> chanDoan.value.maChanDoan && chanDoan.value.tenChanDoan  && chanDoan.value.ketLuan);
    const validThuoc = computed(()=> thuoc.value.tenThuoc && thuoc.value.loaiThuoc && thuoc.value.hoatChat);
    const dsLoaiThuoc = ref([
      { label: 'Thuốc cơ bản', value: 'c' },
      { label: 'Thuốc hướng tâm thần và thuốc tiền chất', value: 'h' },
      { label: 'Thuốc gây nghiện', value: 'n' },
      { label: 'Thuốc y học cổ truyền', value: 'y' },
    ]);

    // if(!(store.state.permission == 'admin')){
    //   router.push({
    //     name: 'home'
    //   });
    // }
    if(!(!!store.state.token)){
      router.push({
        name: 'login'
      });
    }
    const createChanDoan = () => {
        patientRepository.createChanDoan(chanDoan.value)
        .then((response) => {
          const temp = ref({} as ChanDoan);
          chanDoan.value = temp.value;
          getListChanDoan();
          toast.add({
            severity: 'success',
            summary: 'Chẩn đoán',
            detail: 'Thêm chẩn đoán thành công',
            life: 2000
          });
        })
        .catch(err => {
          toast.add({
            severity: 'error',
            summary: 'Lỗi',
            detail:err.response.data,
            life: 4000
          });
        });
    }

    const getListChanDoan = () => {
      patientRepository.getListChanDoan()
          .then((response) => {
            listChanDoan.value = response.data;
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 4000
            });
          });
    }

    const getListThuoc = () => {
      patientRepository.getListThuoc()
          .then((response) => {
            listThuoc.value = response.data;
            listThuoc.value.forEach(x => {
              dsLoaiThuoc.value.forEach(y =>
              {
                if(y.value == x.loaiThuoc) x.loaiThuoc = y.label;
              })
            });
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 4000
            });
          });
    }

    getListChanDoan();
    getListThuoc();

    const createThuoc = () => {
      patientRepository.createThuoc(thuoc.value)
          .then((response) => {
            const temp = ref({} as Thuoc);
            thuoc.value = temp.value;
            getListThuoc();
            toast.add({
              severity: 'success',
              summary: 'Thuốc',
              detail: 'Thêm thuốc thành công',
              life: 2000
            });
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 4000
            });
          });
    }

    const deleteThuoc = (id) => {
      patientRepository.deleteThuoc(id)
          .then((response) => {
            getListThuoc();
            toast.add({
              severity: 'success',
              summary: 'Thông báo',
              detail: 'Xoá thuốc thành công',
              life: 2000
            });
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 4000
            });
          });
    }

    const deleteChuanDoan = (id) => {
      patientRepository.deleteChanDoan(id)
          .then((response) => {
            getListChanDoan();
            toast.add({
              severity: 'success',
              summary: 'Thông báo',
              detail: 'Xoá chẩn đoán thành công',
              life: 2000
            });
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 4000
            });
          });
    }

    return {
      createChanDoan,
      valid,
      error,
      chanDoan,
      thuoc,
      dsLoaiThuoc,
      createThuoc,
      validThuoc,
      getListChanDoan,
      listChanDoan,
      listThuoc,
      deleteChuanDoan,
      deleteThuoc,
    }
  }
}

