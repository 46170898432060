

  import { computed, ref } from 'vue'
  import {useToast} from "primevue/usetoast";
  import axios from "axios";
  import {useStore} from "vuex";
  import {useRouter} from "vue-router";
  import {FileDetail} from "@/models/fileDetail.models";
  import DonThuocRepository from "@/services/DonThuocRepository";

  export default {
    setup() {
      const layout = ref('grid');
      const toast = useToast();
      const show = ref(false);
      const store = useStore();
      const router = useRouter();
      const fileName = ref("");

      // if(!(!!store.state.token)){
      //   router.push({
      //     name: 'home'
      //   });
      // }

      if(!(!!store.state.token)){
        router.push({
          name: 'login'
        });
      }
      let listFileName = ref([] as FileDetail[]);
      const loadingBar = ref(false);
      const loadingBarProcess = ref(false);
      const fileChange = (fileList) => {
        fileName.value = fileList[0].name;
        listFileName.value = [];
        var leng = fileList.length;
        for (var i = 0; i < leng; i++)
        {
          var filetemp = ref({} as FileDetail);
          filetemp.value.nameFile = fileList[i].name;
          filetemp.value.value = fileList[i];
          listFileName.value.push(filetemp.value);
        }
      };
      const upload = () => {
        {
          var files = new FormData();
          listFileName.value.forEach(x =>
          {
            files.append("formFiles", x.value, x.nameFile);
          });
          loadingBar.value = true;
          axios.post(`/api/file/Upload`, files,
              {
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'multipart/form-data'
                }
              }).then(response => {
                  loadingBar.value = false;
                  listFileName.value = [];
                  show.value = true;
                  toast.add({
                    severity: 'success',
                    summary: 'Thành công',
                    detail:'Tải danh sách lên thành công',
                    life: 2000
                  });
          })
              .catch(err => {
                toast.add({
                  severity: 'error',
                  summary: 'Lỗi',
                  detail:err.response.data,
                  life: 2500
                })});
        }
      };
      const createDsBacSi = () => {
        loadingBarProcess.value = true;
        DonThuocRepository.importDsBacSi()
            .then((response) => {
              loadingBarProcess.value = false;
              toast.add({
                severity: 'success',
                summary: 'Thông báo',
                detail: 'Tạo danh sách bác sĩ thành công',
                life: 4000
              });
            })
            .catch(err => {
              toast.add({
                severity: 'error',
                summary: 'Lỗi',
                detail: err.response.data,
                life: 4000
              });
              loadingBarProcess.value = false;
            });
      };
      const downLoadFile = () => {
        axios({
          url: '../api/donthuoc/Download/',
          method: 'GET',
          responseType: 'blob',
        })
            .then((response) => {
              const url = window.URL.createObjectURL(new Blob([response.data], {type: response.data.type}));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download',"dsbs.xlsx");
              document.body.appendChild(link);
              link.click();
            })
            .catch(err => {
              toast.add({
                severity: 'error',
                summary: 'Lỗi',
                detail:err.response.data,
                life: 2500
              })});
      };

      return {
        layout,
        upload,
        loadingBar,
        listFileName,
        fileChange,
        show,
        fileName,
        loadingBarProcess,
        createDsBacSi,
        downLoadFile
      }
    }

  }
