

import { Login } from '../models/login'
import { computed} from 'vue'
import { ref } from 'vue'
import AuthRepository from '../services/AuthRepository'
import { useStore } from 'vuex'
import { useToast } from 'primevue/usetoast'
import { useRouter } from 'vue-router'
import {CoSoKhamBenhInfo} from "@/models/coSoKhamBenhInfo";
import patientRepository from "@/services/PatientRepository";

export default {

  setup (props) {
    const router = useRouter();
    const login = ref({} as Login);
    const store = useStore();
    const toast = useToast();
    const userName = ref("");
    const password = ref("");

    const valid = computed(() => password.value && userName.value)
    const coSoKhamBenhInfo = ref({} as CoSoKhamBenhInfo);
    const getCoSoKhamBenhInfo = () => {
      patientRepository.getCoSoKhamBenhInfo()
          .then((response) => {
            coSoKhamBenhInfo.value = response.data;
            store.dispatch('setTenCoSo', coSoKhamBenhInfo.value.tenCoSo);
            store.dispatch('setDiaChi', coSoKhamBenhInfo.value.diaChi);
            store.dispatch('setSoDienThoai', coSoKhamBenhInfo.value.soDienThoai);
            store.dispatch('setHoVaTen', coSoKhamBenhInfo.value.hoVaTen);
            console.log("###########@@@@@@@@@@ check menu coSoKhamBenhInfo: " + JSON.stringify(coSoKhamBenhInfo.value));
          })
          .catch(err => {
          });
    }

    const doLogin = () => {
      var md5 = require("md5");
      console.log("####################@@@@@@@@@@@@@@@@@@@@@ userPermission auth userName.value: " + userName.value);
      login.value.username = userName.value;
      login.value.password = md5(password.value);
      console.log("####################@@@@@@@@@@@@@@@@@@@@@ userPermission auth login.value: " + JSON.stringify(login.value));
      AuthRepository.auth(login.value)
          .then((response) => {
            console.log("####################@@@@@@@@@@@@@@@@@@@@@ userPermission auth aaa: ");
            getCoSoKhamBenhInfo();
            store.dispatch('setToken', response.data.token)
            toast.add({
              severity: 'success',
              summary: 'Đăng nhập',
              detail: 'Đăng nhập thành công vào hệ thống',
              life: 1000
            });
            AuthRepository.getPermission(login.value)
                .then((responses)=>{
                  store.dispatch('setPermission', responses.data);
                  console.log("####################@@@@@@@@@@@@@@@@@@@@@ userPermission responses: " + JSON.stringify(responses.data));
                })
                .catch(() => {
                });
            router.push({
              name: 'home'
            });
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Đăng nhập thất bại',
              detail:err.response.data,
              life: 4000
            })
          })
    }

    return {
      login,
      doLogin,
      valid,
      password,
      userName,
    }
  }
}

