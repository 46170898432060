import Repository from './Repository'
import { AxiosResponse } from 'axios'
import { Login } from '../models/login'
import { TokenModel } from '../models/token.model'
import {Register} from "@/models/register";
import PasswordModel from "@/models/password.model";
import {UserPermission} from "@/models/permission.model";
import {UserUpdate} from "@/models/userUpdate.model";
import {User} from "@/models/user.model";
import {UserCreate} from "@/models/userCreate.model";
import {UserDetail} from "@/models/userDetail.model";

const resource = '/auth'

export default new class {
  auth (login: Login): Promise<AxiosResponse<TokenModel>> {
    console.log('############################## loginpage auth api')
    return Repository.post<TokenModel>(`${resource}/login`, login)
  }
  getPermission (login: Login): Promise<AxiosResponse<any>> {
    console.log('############################## loginpage getPermission api')
    return Repository.post<any>(`${resource}/getPermission`, login)
  }
  registerUser(register: UserCreate): Promise<any>{
    return Repository.post<any>(`${resource}/register`, register);
  }
  changePassword(changePassword: PasswordModel): Promise<any>{
    return Repository.post<TokenModel>(`${resource}/password`, changePassword);
  }
  userPermission(login: Login): Promise<AxiosResponse<UserPermission>> {
    return Repository.post<UserPermission>(`${resource}/getpermission`, login);
  }
  getUserInfo(login: Login): Promise<AxiosResponse<User>> {
    return Repository.post<User>(`${resource}/getUserInfo`, login);
  }
  userUpdate(userUpdate: UserUpdate): Promise<AxiosResponse<User>> {
    return Repository.post<User>(`${resource}/updateUser`, userUpdate);
  }
  getById(id: number):  Promise<AxiosResponse<User>> {
    return Repository.get<User>(`${resource}/getById/${id}`);
  }
  getListUser():  Promise<AxiosResponse<any>> {
    return Repository.get<UserDetail[]>(`${resource}/getListUser`);
  }
}
