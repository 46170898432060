

import { computed, ref } from 'vue'
import AuthRepository from '@/services/AuthRepository'
import { useToast } from "primevue/usetoast";
import { useRouter } from 'vue-router';
import {useStore} from "vuex";
import {useConfirm} from "primevue/useconfirm";
import {ThongKeDetail} from "@/models/thongKeDetail";
import patientRepository from "@/services/PatientRepository";
import {ChartValue} from "@/models/chartValue";
import {ThongKeBenhNhan} from "@/models/thongKeBenhNhan";
import takeColor from "@/utils/color";

export default {

  setup() {
    const router = useRouter();
    const toast = useToast();
    const store = useStore();
    const error = ref(null);
    const listThongKe = ref([] as ThongKeDetail[]);
    const listThongKeMonth = ref([] as ThongKeDetail[]);
    const listChart = ref([] as ChartValue[]);
    const listChartBS = ref([] as ChartValue[]);
    const thongKeTrongNam = ref([] as ThongKeBenhNhan[]);

    if(!(!!store.state.token)){
      router.push({
        name: 'login'
      });
    }

    patientRepository.getThongKeIn15Day()
        .then((response) => {
          listThongKe.value = response.data;
        })
        .catch(err => {
          toast.add({
            severity: 'error',
            summary: 'Lỗi',
            detail: err.response.data,
            life: 2000
          });
        });

    patientRepository.getThongKeTrongNam()
        .then((response) => {
          thongKeTrongNam.value = response.data;
        })
        .catch(err => {
          toast.add({
            severity: 'error',
            summary: 'Lỗi',
            detail: err.response.data,
            life: 2000
          });
        });

    patientRepository.getChartAge()
        .then((response) => {
          listChart.value = response.data;

          console.log("######@@@@@@@@@@@@@@@@ getChartAge listChart: " + JSON.stringify(listChart.value));
        })
        .catch(err => {
          // toast.add({
          //   severity: 'error',
          //   summary: 'Lỗi',
          //   detail: err.response.data,
          //   life: 2000
          // });
        });

        patientRepository.getChartBsInYear()
        .then((response) => {
          listChartBS.value = response.data;

          console.log("######@@@@@@@@@@@@@@@@ getChartAge listChart: " + JSON.stringify(listChart.value));
        })
        .catch(err => {
          // toast.add({
          //   severity: 'error',
          //   summary: 'Lỗi',
          //   detail: err.response.data,
          //   life: 2000
          // });
        });

    patientRepository.getThongKeIn12Month()
        .then((response) => {
          listThongKeMonth.value = response.data;
        })
        .catch(err => {
          toast.add({
            severity: 'error',
            summary: 'Lỗi',
            detail: err.response.data,
            life: 2000
          });
        });

    const chartOptions = computed(() => {
      const data = listThongKe.value;
      return {
        chart: {
          type: 'area',
          dropShadow: {
            enabled: true,
            color: '#000',
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2
          },
          foreColor: '#373d3f',
          toolbar: {
            show: false
          },
        },
        tooltip: {
          show: true,
          style: {
            fontSize: '12px',
          },
          onDatasetHover: {
            highlightDataSeries: true,
          },
          background: 'lightblue',
          color: 'darkblue',
          y: {
            formatter: function (val) {
              return val + " lượt";
            }
          }
        },
        title: {
          text: "Thống kê lượt khám trong 15 ngày",
          align: 'center',
          margin: 0,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontSize:  '12px',
            fontWeight:  'bold',
            color:  'gray'
          },
        },
        colors: ['#05b2fc'],
        // fillSeriesColor: true,
        dataLabels: {
          enabled: true,
        },
        stroke: {
          curve: 'smooth'
        },
        grid: {
          borderColor: '#e7e7e7',
          row: {
            colors: ['transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
        },
        markers: {
          size: 1
        },
        xaxis: {
          categories: data.map(x => x.thoiGian),
        },
        legend: {
          position: 'top',
          horizontalAlign: 'right',
          floating: true,
          offsetY: -25,
          offsetX: -5
        }
      }
    });

    const series = computed(() => {
      const data = listThongKe.value;
      return [
        {
          name: "Lượt khám",
          data: data.map(x => x.soLuong)
        }
      ]
    });

    const chartOptionsMonth = computed(() => {
      const data = listThongKeMonth.value;
      return {
        chart: {
          type: 'area',
          dropShadow: {
            enabled: true,
            color: '#000',
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.1
          },
          toolbar: {
            show: false
          },
          rotate: 0
        },
        title: {
          text: "Thống kê lượt khám trong 12 tháng",
          align: 'center',
          margin: 0,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontSize:  '12px',
            fontWeight:  'bold',
            color:  'gray'
          },
        },
        colors: ['#FFA726'],
        dataLabels: {
          enabled: true,
          style: {
            fontSize: '10px',
          }
        },
        stroke: {
          curve: 'smooth'
        },
        grid: {
          borderColor: '#e7e7e7',
          row: {
            colors: ['transparent'], // takes an array which will be repeated on columns
            opacity: 0.8
          },
        },
        markers: {
          size: 1
        },
        xaxis: {
          categories: data.map(x => x.thoiGian),
        },
        legend: {
          position: 'top',
          horizontalAlign: 'right',
          floating: true,
          offsetY: -25,
          offsetX: -5
        }
      }
    })

    const seriesMonth = computed(() => {
      const data = listThongKeMonth.value;
      return [
        {
          name: "Lượt khám",
          data: data.map(x => x.soLuong)
        }
      ]
    })

    const chartAgeOptions = computed(() => {
      const data = listChart.value;
      console.log("&&^^^^^^^^^^^^^^^^^ chartAgeOptions data: " + JSON.stringify(data));
      return{
        chart: {
          height: '100%',
          width: '100%',
          type: 'donut',
          toolbar: {
            show: false
          },
          dropShadow: {
            enabled: true,
            color: '#706f6f',
            top: -1,
            left: 1,
            blur: 1,
            opacity: 0.1
          }
        },
        title: {
          text: "Thống kê số lượt khám theo độ tuổi trong 1 năm",
          align: 'center',
          margin: 0,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontSize:  '12px',
            fontWeight:  'bold',
            color:  'gray'
          },
        },
        zoom: {
          enabled: true,
          type: 'x',
          autoScaleYaxis: false,
          zoomedArea: {
            fill: {
              color: '#90CAF9',
              opacity: 0.4
            },
            stroke: {
              color: '#0D47A1',
              opacity: 0.4,
              width: 1
            }
          }
        },
        selection: {
          enabled: true,
        },
        dataLabels: {
          enabled: true,
          offset: 0,
          minAngleToShowLabel: 10,
        },
        tooltip: {
          theme: false,
          y: {
            formatter: function (val) {
              return val + " lượt khám bệnh";
            }
          }
        },
        legend: {
          position: 'bottom',
          horizontalAlign: 'center',
        },
        labels: data.map(x => x.name),
      }
    })

    const ageSeries = computed(() => {
      const data = listChart.value;
      return data.map(x => x.value)
    })

    const seriesYear = computed(() => {
      const data = thongKeTrongNam.value;
      return [{
        name: 'Số lượt khám năm trước',
        data: data.map(x => x.namTruoc)
      },
        {
          name: 'Số lượt khám năm nay',
          data: data.map(x => x.namNay)
        },
      ]
    });

      const optionsYear = computed(() => {
      const data = thongKeTrongNam.value;
      return{
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '100%',
            endingShape: 'flat',
            borderRadius: 5,
            dataLabels: {
              position: "top"
            },
          }
        },
        stroke: {
          width: 1,
          colors: ['#fff']
        },
        title: {
          text: "Thống kê số lượt khám chi tiết trong 2 năm",
          align: 'center',
          margin: 0,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontSize:  '12px',
            fontWeight:  'bold',
            color:  'gray'
          },
        },
        dataLabels: {
          style: {
            fontSize: '10px',
            colors: ["#304758"]
          }
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + " lượt khám";
            }
          },

        },
        chart: {
          id: 'Thống_kê_lượt_khám',
          //stacked: true
          toolbar: {
            show: false
          },
          height: '100%'
        },
        labels: {
          show: false,
          formatter: function (val) {
            return val + " lượt khám";
          }
        },
        xaxis: {
          categories: data.map(x => x.thang)
        },
      }
    })

    const listColor = ref([] as string[]);
    listColor.value = takeColor(30);

    const chartBSOptions = computed(() => {
      const data = listChartBS.value;
      return{
        chart: {
          height: '100%',
          width: '100%',
          type: 'pie',
          toolbar: {
            show: false
          },
        },
        // responsive: [{
        //   breakpoint: 80,
        //   options: {
        //     chart: {
        //       width: 50
        //     },
        //     // legend: {
        //     //   position: 'bottom'
        //     // }
        //   }
        // }],
        zoom: {
          enabled: true,
          type: 'x',
          autoScaleYaxis: false,
          zoomedArea: {
            fill: {
              color: '#90CAF9',
              opacity: 0.4
            },
            stroke: {
              color: '#0D47A1',
              opacity: 0.4,
              width: 1
            }
          }
        },
        title: {
          text: "Thống kê số lượt khám theo bác sĩ trong 1 năm",
          align: 'center',
          margin: 0,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontSize:  '12px',
            fontWeight:  'bold',
            color:  'gray'
          },
        },
        colors: listColor.value,
        selection: {
          enabled: true,
        },
        dataLabels: {
          enabled: true,
          offset: 0,
          minAngleToShowLabel: 10,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + " lượt khám bệnh";
            }
          }
        },
        legend: {
          position: 'bottom',
          horizontalAlign: 'center',
        },
        labels: data.map(x => x.name),
      }
    })

    const ageSeriesBS = computed(() => {
      const data = listChartBS.value;
      return data.map(x => x.value)
    })

    return {
      chartOptions,
      series,
      chartOptionsMonth,
      seriesMonth,
      chartAgeOptions,
      ageSeries,
      chartBSOptions,
      ageSeriesBS,
      seriesYear,
      optionsYear
    }
  }
}

