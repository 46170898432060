import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

export default createStore({
  state: {
    token: '',
    permission: '',
    id:'',
    tenCoSo: '',
    diaChi: '',
    soDienThoai: '',
    hoVaTen: '',
  },
  getters: {
    isLoggedIn (state): boolean {
      return !!state.token
    }
  },
  mutations: {
    setToken (state, token) {
      state.token = token
    },
    clearToken (state) {
      state.token = ''
    },
    setPermission (state, permisson) {
      state.permission = permisson
    },
    setTenCoSo (state, tenCoSo) {
      state.tenCoSo = tenCoSo
    },
    setDiaChi (state, diaChi) {
      state.diaChi = diaChi
    },
    setSoDienThoai (state, soDienThoai) {
      state.soDienThoai = soDienThoai
    },
    setHoVaTen (state, hoVaTen) {
      state.hoVaTen = hoVaTen
    },
    clearPermission (state) {
      state.permission = ''
    },
    setId (state, id) {
      state.id = id
    },
    clearId (state) {
      state.id = ''
    },
    clearTenCoSo (state) {
      state.tenCoSo = ''
    },
    clearDiaChi (state) {
      state.diaChi = ''
    },
    clearSoDienThoai (state) {
      state.soDienThoai = ''
    },
    clearHoVaTen (state) {
      state.hoVaTen = ''
    }
  },
  actions: {
    setToken ({ commit }, token) {
      commit('setToken', token)
    },
    clearToken ({ commit }) {
      commit('clearToken')
    },
    setPermission ({ commit }, permission) {
      commit('setPermission', permission)
    },
    clearPermission ({ commit }) {
      commit('clearPermission')
    },
    setId ({ commit }, id) {
      commit('setId', id)
    },
    clearId ({ commit }) {
      commit('clearId')
    },
    setTenCoSo ({ commit }, tenCoSo) {
      commit('setTenCoSo', tenCoSo)
    },
    setDiaChi ({ commit }, diaChi) {
      commit('setDiaChi', diaChi)
    },
    setSoDienThoai ({ commit }, soDienThoai) {
      commit('setSoDienThoai', soDienThoai)
    },
    setHoVaTen ({ commit }, hoVaTen) {
      commit('setHoVaTen', hoVaTen)
    },
    clearTenCoSo ({ commit }) {
      commit('clearTenCoSo')
    },
    clearDiaChi ({ commit }) {
      commit('clearDiaChi')
    },
    clearSoDienThoai ({ commit }) {
      commit('clearSoDienThoai')
    },
    clearHoVaTen ({ commit }) {
      commit('clearHoVaTen')
    },
  },
  modules: {
  },
  plugins: [createPersistedState()]
})
