

import { computed, ref } from 'vue'
import AuthRepository from '@/services/AuthRepository'
import { useToast } from "primevue/usetoast";
import { useRouter } from 'vue-router'
import {Register} from "../models/register";
import {useStore} from "vuex";
import {BacSiCreate} from "@/models/bacSiCreate";
import DonThuocRepository from "@/services/DonThuocRepository";

export default {

  setup(){
    const router = useRouter();
    const register = ref({} as BacSiCreate);
    const toast = useToast();
    const store = useStore();
    const error = ref(null);

    const valid = computed(()=> register.value.password && register.value.hovaten  && register.value.cchn);

    // if(!(store.state.permission == 'admin')){
    //   router.push({
    //     name: 'home'
    //   });
    // }
    if(!(!!store.state.token)){
      router.push({
        name: 'login'
      });
    }
    const doRegister = () => {
        DonThuocRepository.dangKyBacSi(register.value)
                .then((response) => {
                  toast.add({
                    severity: 'success',
                    summary: 'Đăng ký',
                    detail: 'Đăng ký bác sĩ thành công vào hệ thống',
                    life: 2000
                  });
                })
                .catch(err => {
                  toast.add({
                    severity: 'error',
                    summary: 'Lỗi',
                    detail:err.response.data,
                    life: 4000
                  });
                });
    }

    return {
      register,
      doRegister,
      valid,
      error,
    }
  }
}

