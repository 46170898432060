import Repository from './Repository';
import {NguoiBenh} from "@/models/nguoiBenh";
import {ChanDoan} from "@/models/chanDoan";
import {Thuoc} from "@/models/thuoc";
import {KhamBenh} from "@/models/khamBenh";
import {CoSoKhamBenhCreate} from "@/models/coSoKhamBenhCreate";
import {NguoiBenhUpdate} from "@/models/nguoiBenhUpdate";
const resource = '/patient';

export default new class{
    createBenhNhan(nguoiBenh: NguoiBenh): Promise<any>{
        return Repository.post<any>(`${resource}/createBenhNhan`, nguoiBenh);
    }
    updateBenhNhan(nguoiBenh: NguoiBenh): Promise<any>{
        return Repository.post<any>(`${resource}/updateBenhNhan`, nguoiBenh);
    }
    getBenhNhanByCccd(st: string): Promise<any>{
        return Repository.get<any>(`${resource}/getBenhNhanByCccd/${st}`);
    }
    getBenhNhanInfo(): Promise<any>{
        return Repository.get<any>(`${resource}/getListBenhNhanInfo`);
    }
    getBenhNhanById(id: number): Promise<any>{
        return Repository.get<any>(`${resource}/getBenhNhanById/${id}`);
    }
    createChanDoan(chanDoan: ChanDoan): Promise<any>{
        return Repository.post<any>(`${resource}/createChanDoan`, chanDoan);
    }
    getListChanDoan(): Promise<any>{
        return Repository.get<any>(`${resource}/getListChanDoan`);
    }
    deleteChanDoan(id: number): Promise<any>{
        return Repository.delete<any>(`${resource}/deleteChanDoan/${id}`);
    }
    createThuoc(thuoc: Thuoc): Promise<any>{
        return Repository.post<any>(`${resource}/createThuoc`, thuoc);
    }
    getListThuoc(): Promise<any>{
        return Repository.get<any>(`${resource}/getListThuoc`);
    }
    deleteThuoc(id: number): Promise<any>{
        return Repository.delete<any>(`${resource}/deleteThuoc/${id}`);
    }
    getCoSoKhamBenhInfo(): Promise<any>{
        return Repository.get<any>(`${resource}/getCoSoKhamBenhInfo`);
    }
    createKhamBenh(khamBenh: KhamBenh): Promise<any>{
        return Repository.post<any>(`${resource}/createKhamBenh`, khamBenh);
    }
    getKhamBenhById(id: number): Promise<any>{
        return Repository.get<any>(`${resource}/getKhamBenhById/${id}`);
    }
    getListKhamBenh(id: number): Promise<any>{
        return Repository.get<any>(`${resource}/getListKhamBenh/${id}`);
    }
    getListKhamBenhWithTime(dt1: number,dt2: number): Promise<any>{
        return Repository.get<any>(`${resource}/dsKhamBenhWithTime/${dt1}/${dt2}`);
    }
    getThongKeIn15Day(): Promise<any>{
        return Repository.get<any>(`${resource}/getThongKeIn15Day`);
    }
    getThongKeIn12Month(): Promise<any>{
        return Repository.get<any>(`${resource}/getThongKeIn12Month`);
    }
    createCoSoKhamBenh(coSoKhamBenhCreate: CoSoKhamBenhCreate): Promise<any>{
        return Repository.post<any>(`${resource}/createCoSoKB`, coSoKhamBenhCreate);
    }
    getListCoSoKhamBenh(): Promise<any>{
        return Repository.get<any>(`${resource}/getListCoSoKhamBenh`);
    }
    getChartAge(): Promise<any>{
        return Repository.get<any>(`${resource}/getChartAge`);
    }
    getThongKeTrongNam(): Promise<any>{
        return Repository.get<any>(`${resource}/getThongKeTrongNam`);
    }
    getChartBsInYear(): Promise<any>{
        return Repository.get<any>(`${resource}/getChartBsInYear`);
    }
}
