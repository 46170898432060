import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import LoginPage from '../pages/LoginPage.vue';
import Home from "@/pages/Home.vue";
import GoiDonThuoc from "@/pages/GoiDonThuoc.vue";
import ThemDanhSachBacSi from "@/pages/ThemDanhSachBacSi.vue";
import DanhSachBacSi from "@/pages/DanhSachBacSi.vue";
import DangKyBacSi from "@/pages/DangKyBacSi.vue";
import CapNhatBacSi from "@/pages/CapNhatBacSi.vue";
import LichSuDongBo from "@/pages/LichSuDongBo.vue";
import DongBoLaiDonThuoc from "@/pages/DongBoLaiDonThuoc.vue";
import ChiTietDonThuoc from "@/pages/ChiTietDonThuoc.vue";
import LichSuLuuTru from "@/pages/LichSuLuuTru.vue";
import themKhamBenh from "@/pages/ThemKhamBenh.vue";
import dangKyThongTin from "@/pages/DangKyThongTin.vue";
import dangKyTaiKhoan from "@/pages/DangKyTaiKhoan.vue";
import danhSachTaiKhoan from "@/pages/DanhSachTaiKhoan.vue";
import danhSachKhamBenh from "@/pages/DanhSachKhamBenh.vue";
import thongKePage from "@/pages/ThongKePage.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: '',
    component: Home,
    props: true
  },
  {
    path: '/login',
    name: 'login',
    component: LoginPage,
    props: true
  },
  {
    path: '/home',
    name: 'home',
    component: Home,
    props: true
  },
  {
    path: '/themKhamBenh',
    name: 'themKhamBenh',
    component: themKhamBenh,
    props: true
  },
  {
    path: '/themdsbacsi',
    name: 'themdsbacsi',
    component: ThemDanhSachBacSi,
    props: true
  },
  {
    path: '/danhsachbacsi',
    name: 'danhsachbacsi',
    component: DanhSachBacSi,
    props: true
  },
  {
    path: '/dangkybacsi',
    name: 'dangkybacsi',
    component: DangKyBacSi,
    props: true
  },
  {
    path: '/lichsudongbo',
    name: 'lichsudongbo',
    component: LichSuDongBo,
    props: true
  },
  {
    path: '/capnhatbacsi/:id',
    name: 'capnhatbacsi',
    component: CapNhatBacSi,
    props: true
  },
  {
    path: '/chitietdonthuoc/:id',
    name: 'chitietdonthuoc',
    component: ChiTietDonThuoc,
    props: true
  },
  {
    path: '/dongbolaidonthuoc',
    name: 'dongbolaidonthuoc',
    component: DongBoLaiDonThuoc,
    props: true
  },
  {
    path: '/lichsuluutru',
    name: 'lichsuluutru',
    component: LichSuLuuTru,
    props: true
  },
  {
    path: '/dangKyThongTin',
    name: 'dangKyThongTin',
    component: dangKyThongTin,
    props: true
  },
  {
    path: '/dangKyTaiKhoan',
    name: 'dangKyTaiKhoan',
    component: dangKyTaiKhoan,
    props: true
  },
  {
    path: '/danhSachTaiKhoan',
    name: 'danhSachTaiKhoan',
    component: danhSachTaiKhoan,
    props: true
  },
  {
    path: '/danhSachKhamBenh',
    name: 'danhSachKhamBenh',
    component: danhSachKhamBenh,
    props: true
  },
  {
    path: '/thongKe',
    name: 'thongKe',
    component: thongKePage,
    props: true
  },
  // {
  //   path: '/statistical',
  //   name: 'statistical',
  //   component: Statistical,
  //   props: true
  // },
  // {
  //   path: '/showlistinfo/:id',
  //   name: 'showlistinfo',
  //   component: ShowListInfo,
  //   props: true
  // },
  // {
  //   path: '/guidonthuoc',
  //   name: 'guidonthuoc',
  //   component: GoiDonThuoc,
  //   props: true
  // },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
