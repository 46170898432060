

import { computed, ref } from 'vue'
import AuthRepository from '@/services/AuthRepository'
import { useToast } from "primevue/usetoast";
import { useRouter } from 'vue-router'
import {Register} from "../models/register";
import {useStore} from "vuex";
import {BacSiCreate} from "@/models/bacSiCreate";
import DonThuocRepository from "@/services/DonThuocRepository";
import {DonThuocChiTiet} from "@/models/donThuocChiTiet";
import {ChuanDoan} from "@/models/chuanDoan";
import {ThongTinThuoc} from "@/models/ThongTinThuoc";
import {ChiTietThongTin} from "@/models/chiTietThongTin";

export default {
  props: {
    id: String,
  },

  setup(props){
    const router = useRouter();
    const donThuoc = ref({} as DonThuocChiTiet);
    const toast = useToast();
    const store = useStore();
    const error = ref(null);
    const chuanDoan = ref([] as ChuanDoan[]);
    const thongtin = ref([] as ThongTinThuoc[]);
    const chitiet = ref({} as ChiTietThongTin);

    // if(!(store.state.permission == 'admin')){
    //   router.push({
    //     name: 'home'
    //   });
    // }
    // if(!(!!store.state.token)){
    //   router.push({
    //     name: 'login'
    //   });
    // }
      DonThuocRepository.donThuocChiTiet(props.id)
          .then((response) => {
            donThuoc.value = response.data;
            chuanDoan.value = donThuoc.value.chan_doan;
            thongtin.value = donThuoc.value.thong_tin_don_thuoc;
          })
          .catch(err => {
            toast.add({
              severity: 'error',
              summary: 'Lỗi',
              detail:err.response.data,
              life: 4000
            });
          });
    DonThuocRepository.chiTietTT(props.id)
        .then((response) => {
          chitiet.value = response.data;
        })
        .catch(err => {
          toast.add({
            severity: 'error',
            summary: 'Lỗi',
            detail:err.response.data,
            life: 4000
          });
        });

    return {
      donThuoc,
      chuanDoan,
      thongtin,
      chitiet,
      error,
    }
  }
}

